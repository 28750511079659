import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Venice - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/venice/"
      image="https://kiwicacahuate.com/venice/venice.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/venice.webp, /venice/venice.webp 2x" />
                <source srcSet="/venice/venice.jpg, /venice/venice.jpg 2x" />
                <img src="/venice/venice.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/001.webp, /venice/thumbnail/001-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/001.jpg, /venice/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/002.webp, /venice/thumbnail/002-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/002.jpg, /venice/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/003.webp, /venice/thumbnail/003-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/003.jpg, /venice/thumbnail/003-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/003.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/004.webp, /venice/thumbnail/004-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/004.jpg, /venice/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/005.webp, /venice/thumbnail/005-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/005.jpg, /venice/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/007.webp, /venice/thumbnail/007-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/007.jpg, /venice/thumbnail/007-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/007.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/008.webp, /venice/thumbnail/008-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/008.jpg, /venice/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/009.webp, /venice/thumbnail/009-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/009.jpg, /venice/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/010.webp, /venice/thumbnail/010-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/010.jpg, /venice/thumbnail/010-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/010.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/011.webp, /venice/thumbnail/011-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/011.jpg, /venice/thumbnail/011-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/011.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/012.webp, /venice/thumbnail/012-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/012.jpg, /venice/thumbnail/012-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/012.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/013.webp, /venice/thumbnail/013-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/013.jpg, /venice/thumbnail/013-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/013.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/014.webp, /venice/thumbnail/014-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/014.jpg, /venice/thumbnail/014-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/014.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/015.webp, /venice/thumbnail/015-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/015.jpg, /venice/thumbnail/015-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/015.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/016.webp, /venice/thumbnail/016-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/016.jpg, /venice/thumbnail/016-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/016.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/017.webp, /venice/thumbnail/017-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/017.jpg, /venice/thumbnail/017-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/017.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/018.webp, /venice/thumbnail/018-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/018.jpg, /venice/thumbnail/018-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/018.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/019.webp, /venice/thumbnail/019-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/019.jpg, /venice/thumbnail/019-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/019.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/020.webp, /venice/thumbnail/020-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/020.jpg, /venice/thumbnail/020-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/020.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/021.webp, /venice/thumbnail/021-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/021.jpg, /venice/thumbnail/021-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/021.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/022.webp, /venice/thumbnail/022-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/022.jpg, /venice/thumbnail/022-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/022.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/023.webp, /venice/thumbnail/023-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/023.jpg, /venice/thumbnail/023-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/023.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/024.webp, /venice/thumbnail/024-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/024.jpg, /venice/thumbnail/024-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/024.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/025.webp, /venice/thumbnail/025-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/025.jpg, /venice/thumbnail/025-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/025.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/026.webp, /venice/thumbnail/026-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/026.jpg, /venice/thumbnail/026-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/026.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/027.webp, /venice/thumbnail/027-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/027.jpg, /venice/thumbnail/027-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/027.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/028.webp, /venice/thumbnail/028-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/028.jpg, /venice/thumbnail/028-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/028.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/029.webp, /venice/thumbnail/029-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/029.jpg, /venice/thumbnail/029-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/029.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/030.webp, /venice/thumbnail/030-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/030.jpg, /venice/thumbnail/030-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/030.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/031.webp, /venice/thumbnail/031-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/031.jpg, /venice/thumbnail/031-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/031.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/032.webp, /venice/thumbnail/032-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/032.jpg, /venice/thumbnail/032-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/032.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/033.webp, /venice/thumbnail/033-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/033.jpg, /venice/thumbnail/033-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/033.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/034.webp, /venice/thumbnail/034-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/034.jpg, /venice/thumbnail/034-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/034.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/035.webp, /venice/thumbnail/035-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/035.jpg, /venice/thumbnail/035-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/035.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/venice/thumbnail/036.webp, /venice/thumbnail/036-2x.webp 2x" />
                <source srcSet="/venice/thumbnail/036.jpg, /venice/thumbnail/036-2x.jpg 2x" />
                <img
                  src="/venice/thumbnail/036.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
